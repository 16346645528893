@import 'styles/variables';
@import 'styles/mixins';

.popup {
	&__children {
		height: calc(100% - (31px + 48px));
	}

	&__wrapper {
		height: 100%;
	}

	&__head {
		margin-bottom: 32px;
	}

	&__title {
		font-size: 16px !important;
		font-weight: 600 !important;
		line-height: 24px !important;
		color: $gray_9 !important;
	}
}

.form {
	position: relative;
	height: 100%;

	&__block {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	&__comment {
		height: 54px !important;
		border-radius: 5px !important;
		border: 1px solid $gray_4 !important;
		background-color: $gray_2 !important;
		font-size: 14px;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 22px;
		color: $gray_7;
		resize: none;

		&__root {
			margin-bottom: 16px;
		}
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		//padding-top: 24px;
	}

	&__cancel {
		margin-right: 16px !important;

		@include cancelBtn;
	}

	&__legend {
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		color: $gray_9;
		margin-bottom: 10px;
	}

	&__select {
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;

		&__label {
			font-size: 14px !important;
			font-weight: 500 !important;
			line-height: 22px !important;
			margin-bottom: 5px !important;
			color: $gray_7 !important;
		}

		&__wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-start !important;
		}
	}

	&__input {
		&__label {
			font-size: 14px !important;
			font-weight: 500 !important;
			line-height: 22px !important;
			margin-bottom: 5px !important;
			color: $gray_7 !important;
		}

		&__hint {
			margin-left: 4px;
			margin-bottom: 5px;
		}
	}

	&__minutes {
		max-width: 100%;
	}

	&__minutes[type='number']::-webkit-inner-spin-button,
	&__minutes[type='number']::-webkit-outer-spin-button {
		appearance: none !important;
	}
}

textarea.form__comment::placeholder {
	color: $gray_7;
}
